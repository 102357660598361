/**
    Support for SASS is deprecated as of v3.18.

    The files remain here if your build is dependent on them
    but they will not receive updates in future releases. All
    SASS variables have been translated into CSS variables, so
    migration should be quite simple if you'd like to move over.

    In v4, these files will be removed.
 */
.v-select {
  position: relative;
  font-family: inherit;
}
.v-select,
.v-select * {
  box-sizing: border-box;
}

/* KeyFrames */
@-webkit-keyframes vSelectSpinner-ltr {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
@-webkit-keyframes vSelectSpinner-rtl {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(-360deg);
}
}
@keyframes vSelectSpinner-ltr {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
@keyframes vSelectSpinner-rtl {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(-360deg);
}
}
/* Dropdown Default Transition */
.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}
.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}

/** Component States */
/*
 * Disabled
 *
 * When the component is disabled, all interaction
 * should be prevented. Here we modify the bg color,
 * and change the cursor displayed on the interactive
 * components.
 */
[dir] .vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear, [dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected, [dir] .vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: rgb(248, 248, 248);
}

/*
 *  RTL - Right to Left Support
 *
 *  Because we're using a flexbox layout, the `dir="rtl"`
 *  HTML attribute does most of the work for us by
 *  rearranging the child elements visually.
 */
.v-select[dir=rtl] .vs__actions {
  padding: 0 3px 0 6px;
}
.v-select[dir=rtl] .vs__clear {
  margin-left: 6px;
  margin-right: 0;
}
.v-select[dir=rtl] .vs__deselect {
  margin-left: 0;
  margin-right: 2px;
}
.v-select[dir=rtl] .vs__dropdown-menu {
  text-align: right;
}

/**
    Dropdown Toggle

    The dropdown toggle is the primary wrapper of the component. It
    has two direct descendants: .vs__selected-options, and .vs__actions.

    .vs__selected-options holds the .vs__selected's as well as the
    main search input.

    .vs__actions holds the clear button and dropdown toggle.
 */
.vs__dropdown-toggle {
  appearance: none;
  display: flex;
  white-space: normal;
}
[dir] .vs__dropdown-toggle {
  padding: 0 0 4px 0;
  background: none;
  border: 1px solid #d8d6de;
  border-radius: 0.3rem;
}
.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
}
[dir] .vs__selected-options {
  padding: 0 2px;
}
.vs__actions {
  display: flex;
  align-items: center;
}
[dir=ltr] .vs__actions {
  padding: 4px 6px 0 3px;
}
[dir=rtl] .vs__actions {
  padding: 4px 3px 0 6px;
}

/* Dropdown Toggle States */
[dir] .vs--searchable .vs__dropdown-toggle {
  cursor: text;
}
[dir] .vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}
[dir] .vs--open .vs__dropdown-toggle {
  border-bottom-color: transparent;
}
[dir=ltr] .vs--open .vs__dropdown-toggle {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
[dir=rtl] .vs--open .vs__dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
  transition: transform 150ms cubic-bezier(1, -0.115, 0.975, 0.855);
}
[dir] .vs__open-indicator {
  transform: scale(1);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}
[dir=ltr] .vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}
[dir=rtl] .vs--open .vs__open-indicator {
  transform: rotate(-180deg) scale(1);
}
.vs--loading .vs__open-indicator {
  opacity: 0;
}

/* Clear Button */
.vs__clear {
  fill: rgba(60, 60, 60, 0.5);
}
[dir] .vs__clear {
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
[dir=ltr] .vs__clear {
  margin-right: 8px;
}
[dir=rtl] .vs__clear {
  margin-left: 8px;
}

/* Dropdown Menu */
.vs__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 1px);
  z-index: 1000;
  width: 100%;
  max-height: 350px;
  min-width: 160px;
  overflow-y: auto;
  list-style: none;
}
[dir] .vs__dropdown-menu {
  padding: 5px 0;
  margin: 0;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #d8d6de;
  border-top-style: none;
  border-radius: 0 0 0.3rem 0.3rem;
  background: #fff;
}
[dir=ltr] .vs__dropdown-menu {
  left: 0;
  text-align: left;
}
[dir=rtl] .vs__dropdown-menu {
  right: 0;
  text-align: right;
}
[dir] .vs__no-options {
  text-align: center;
}

/* List Items */
.vs__dropdown-option {
  line-height: 1.42857143; /* Normalize line height */
  display: block;
  color: #333; /* Overrides most CSS frameworks */
  white-space: nowrap;
}
[dir] .vs__dropdown-option {
  padding: 3px 20px;
  clear: both;
  cursor: pointer;
}
.vs__dropdown-option--highlight {
  color: #0875d6 !important;
}
[dir] .vs__dropdown-option--highlight {
  background: rgba(8, 117, 214, 0.12);
}
.vs__dropdown-option--deselect {
  color: #fff;
}
[dir] .vs__dropdown-option--deselect {
  background: #fb5858;
}
.vs__dropdown-option--disabled {
  color: rgba(60, 60, 60, 0.5);
}
[dir] .vs__dropdown-option--disabled {
  background: inherit;
  cursor: inherit;
}

/* Selected Tags */
.vs__selected {
  display: flex;
  align-items: center;
  color: #333;
  line-height: 1.8;
  z-index: 0;
}
[dir] .vs__selected {
  background-color: #0875d6;
  border: 0 solid rgba(60, 60, 60, 0.26);
  border-radius: 0.3rem;
  margin: 4px 2px 0px 2px;
  padding: 0 0.25em;
}
.vs__deselect {
  display: inline-flex;
  appearance: none;
  fill: rgba(60, 60, 60, 0.5);
}
[dir] .vs__deselect {
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  text-shadow: 0 1px 0 #fff;
}
[dir=ltr] .vs__deselect {
  margin-left: 4px;
}
[dir=rtl] .vs__deselect {
  margin-right: 4px;
}

/* States */
[dir] .vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
}
.vs--single.vs--open .vs__selected, .vs--single.vs--loading .vs__selected {
  position: absolute;
  opacity: 0.4;
}
.vs--single.vs--searching .vs__selected {
  display: none;
}

/* Search Input */
/**
 * Super weird bug... If this declaration is grouped
 * below, the cancel button will still appear in chrome.
 * If it's up here on it's own, it'll hide it.
 */
.vs__search::-webkit-search-cancel-button {
  display: none;
}
.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration,
.vs__search::-ms-clear {
  display: none;
}
.vs__search,
.vs__search:focus {
  appearance: none;
  line-height: 1.8;
  font-size: 1em;
  outline: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
}
[dir] .vs__search, [dir] .vs__search:focus {
  border: 1px solid transparent;
  margin: 4px 0 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
}
[dir=ltr] .vs__search, [dir=ltr] .vs__search:focus {
  border-left: none;
}
[dir=rtl] .vs__search, [dir=rtl] .vs__search:focus {
  border-right: none;
}
.vs__search::placeholder {
  color: #6e6b7b;
}

/**
    States
 */
.vs--unsearchable .vs__search {
  opacity: 1;
}
[dir] .vs--unsearchable:not(.vs--disabled) .vs__search {
  cursor: pointer;
}
.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}

/* Loading Spinner */
.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  transition: opacity 0.1s;
}
[dir] .vs__spinner {
  border-top: 0.9em solid rgba(100, 100, 100, 0.1);
  border-bottom: 0.9em solid rgba(100, 100, 100, 0.1);
  transform: translateZ(0);
}
[dir=ltr] .vs__spinner {
  border-right: 0.9em solid rgba(100, 100, 100, 0.1);
  border-left: 0.9em solid rgba(60, 60, 60, 0.45);
  animation:  vSelectSpinner-ltr 1.1s infinite linear;
}
[dir=rtl] .vs__spinner {
  border-left: 0.9em solid rgba(100, 100, 100, 0.1);
  border-right: 0.9em solid rgba(60, 60, 60, 0.45);
  animation:  vSelectSpinner-rtl 1.1s infinite linear;
}
.vs__spinner,
.vs__spinner:after {
  width: 5em;
  height: 5em;
}
[dir] .vs__spinner, [dir] .vs__spinner:after {
  border-radius: 50%;
}

/* Loading Spinner States */
.vs--loading .vs__spinner {
  opacity: 1;
}
.vs__open-indicator {
  fill: none;
}
[dir] .vs__open-indicator {
  margin-top: 0.15rem;
}
.vs__dropdown-toggle {
  transition: all 0.25s ease-in-out;
}
[dir] .vs__dropdown-toggle {
  padding: 0.59px 0 4px 0;
}
[dir=ltr] .vs--single .vs__dropdown-toggle {
  padding-left: 6px;
}
[dir=rtl] .vs--single .vs__dropdown-toggle {
  padding-right: 6px;
}
.vs__dropdown-option--disabled {
  opacity: 0.5;
}
[dir] .vs__dropdown-option--disabled.vs__dropdown-option--selected {
  background: #0875d6 !important;
}
.vs__dropdown-option {
  color: #6e6b7b;
}
[dir] .vs__dropdown-option, [dir] .vs__no-options {
  padding: 7px 20px;
}
.vs__dropdown-option--selected {
  background-color: #0875d6;
  color: #fff;
  position: relative;
}
.vs__dropdown-option--selected::after {
  content: "";
  height: 1.1rem;
  width: 1.1rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.1rem;
}
[dir=rtl] .vs__dropdown-option--selected::after {
  left: 20px;
  right: unset;
}
.vs__dropdown-option--selected.vs__dropdown-option--highlight {
  color: #fff !important;
  background-color: #0875d6 !important;
}
.vs__clear svg {
  color: #6e6b7b;
}
.vs__selected {
  color: #fff;
}
.v-select.vs--single .vs__selected {
  color: #6e6b7b;
  transition: transform 0.2s ease;
}
[dir] .v-select.vs--single .vs__selected {
  margin-top: 5px;
}
[dir=ltr] .v-select.vs--single .vs__selected input {
  padding-left: 0;
}
[dir=rtl] .v-select.vs--single .vs__selected input {
  padding-right: 0;
}
[dir=ltr] .vs--single.vs--open .vs__selected {
  transform: translateX(5px);
}
[dir=rtl] .vs--single.vs--open .vs__selected {
  transform: translateX(-5px);
}
.vs__selected .vs__deselect {
  color: inherit;
}
.v-select:not(.vs--single) .vs__selected {
  font-size: 0.9rem;
}
[dir] .v-select:not(.vs--single) .vs__selected {
  border-radius: 3px;
  padding: 0 0.6em;
}
[dir=ltr] .v-select:not(.vs--single) .vs__selected {
  margin: 5px 2px 2px 5px;
}
[dir=rtl] .v-select:not(.vs--single) .vs__selected {
  margin: 5px 5px 2px 2px;
}
.v-select:not(.vs--single) .vs__deselect svg {
  vertical-align: text-top;
}
[dir] .v-select:not(.vs--single) .vs__deselect svg {
  transform: scale(0.8);
}
.vs__dropdown-menu {
  top: calc(100% + 0.4rem);
}
[dir] .vs__dropdown-menu {
  border: none;
  border-radius: 3px;
  padding: 0;
}
[dir] .vs--open .vs__dropdown-toggle {
  border-color: #0875d6;
  border-bottom-color: #0875d6;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}
[dir=ltr] .vs--open .vs__dropdown-toggle {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
[dir=rtl] .vs--open .vs__dropdown-toggle {
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.select-size-lg .vs__selected {
  font-size: 1rem !important;
}
[dir] .select-size-lg.vs--single.vs--open .vs__selected {
  margin-top: 6px;
}
.select-size-lg .vs__dropdown-toggle,
.select-size-lg .vs__selected {
  font-size: 1.25rem;
}
[dir] .select-size-lg .vs__dropdown-toggle {
  padding: 5px;
}
[dir] .select-size-lg .vs__dropdown-toggle input {
  margin-top: 0;
}
.select-size-lg .vs__deselect svg {
  vertical-align: middle !important;
}
[dir] .select-size-lg .vs__deselect svg {
  transform: scale(1) !important;
}
[dir] .select-size-sm .vs__dropdown-toggle {
  padding-bottom: 0;
  padding: 1px;
}
[dir] .select-size-sm.vs--single .vs__dropdown-toggle {
  padding: 2px;
}
.select-size-sm .vs__dropdown-toggle,
.select-size-sm .vs__selected {
  font-size: 0.9rem;
}
[dir] .select-size-sm .vs__actions {
  padding-top: 2px;
  padding-bottom: 2px;
}
.select-size-sm .vs__deselect svg {
  vertical-align: middle !important;
}
[dir] .select-size-sm .vs__search {
  margin-top: 0;
}
.select-size-sm.v-select .vs__selected {
  font-size: 0.75rem;
}
[dir] .select-size-sm.v-select .vs__selected {
  padding: 0 0.3rem;
}
[dir] .select-size-sm.v-select:not(.vs--single) .vs__selected {
  margin: 4px 5px;
}
[dir] .select-size-sm.v-select.vs--single .vs__selected {
  margin-top: 1px;
}
[dir] .select-size-sm.vs--single.vs--open .vs__selected {
  margin-top: 4px;
}
.dark-layout .vs__dropdown-toggle {
  color: #b4b7bd;
}
[dir] .dark-layout .vs__dropdown-toggle {
  background: #283046;
  border-color: #404656;
}
.dark-layout .vs__selected-options input {
  color: #b4b7bd;
}
.dark-layout .vs__selected-options input::placeholder {
  color: #676d7d;
}
.dark-layout .vs__actions svg {
  fill: #404656;
}
[dir] .dark-layout .vs__dropdown-menu {
  background: #283046;
}
.dark-layout .vs__dropdown-menu li {
  color: #b4b7bd;
}
.dark-layout .v-select:not(.vs--single) .vs__selected {
  color: #0875d6;
}
[dir] .dark-layout .v-select:not(.vs--single) .vs__selected {
  background-color: rgba(8, 117, 214, 0.12);
}
.dark-layout .v-select.vs--single .vs__selected {
  color: #b4b7bd !important;
}
.dark-layout .vs--disabled .vs__dropdown-toggle,
.dark-layout .vs--disabled .vs__clear,
.dark-layout .vs--disabled .vs__search,
.dark-layout .vs--disabled .vs__selected,
.dark-layout .vs--disabled .vs__open-indicator {
  opacity: 0.5;
}
[dir] .dark-layout .vs--disabled .vs__dropdown-toggle, [dir] .dark-layout .vs--disabled .vs__clear, [dir] .dark-layout .vs--disabled .vs__search, [dir] .dark-layout .vs--disabled .vs__selected, [dir] .dark-layout .vs--disabled .vs__open-indicator {
  background-color: #283046;
}
[dir] .app-timeline .timeline-item {
  padding-bottom: 2.3rem !important;
}
[dir] .app-timeline .timeline-item:last-child {
  padding-bottom: 0px;
}
[dir] .app-timeline li:last-child .text-timeline {
  margin-bottom: 0px;
}